import React, { useContext } from 'react';
import styled from 'styled-components';
import Widget from 'App/WidgetPanel/Widget';
import { WidgetDefinitionWithId } from 'types/inspection-types/WidgetDefinition';
import { useDrop } from 'react-dnd';
import WidgetDragObject from 'types/dnd/WidgetDragObject';
import DNDType from 'types/dnd/DNDType';
import WidgetContext from 'App/WidgetContext';
import { WidgetGridDiv } from 'App/WidgetPanel/styled';
import { IoMdOpen } from 'react-icons/io';
import WidgetHeaderButton from 'App/WidgetPanel/WidgetHeaderButton';

const DEFAULT_WIDGET_SIZE = {
    width: 300,
    height: 300,
};

const CellWrapper = styled.div`
    position: relative;
    padding: 10px;
    height: 100%;
`;

interface Props {
    groupId: string;
    widgetDefinitions?: WidgetDefinitionWithId[];
    openGroupViewCB?: React.MouseEventHandler;
    style?: React.CSSProperties;
}

const WidgetPanelCell: React.FunctionComponent<Props> = ({
    widgetDefinitions = [],
    style,
    groupId,
    openGroupViewCB,
}: Props) => {
    const { moveWidget } = useContext(WidgetContext);

    const [, dropRef] = useDrop<WidgetDragObject, void, void>({
        accept: DNDType.WIDGET,
        drop(item) {
            moveWidget(item.widgetId, groupId);
        },
    });

    const divisions = widgetDefinitions.length == 1 ? 1 : 2;
    const scaleFactor = 1 / divisions;

    return (
        <CellWrapper ref={dropRef} style={style}>
            <WidgetGridDiv $numWidgets={widgetDefinitions.length}>
                {widgetDefinitions.map((wd) => (
                    <div
                        key={wd.widgetId}
                        style={{
                            width: DEFAULT_WIDGET_SIZE.width / divisions,
                            height: DEFAULT_WIDGET_SIZE.height / divisions,
                        }}
                    >
                        <div
                            style={{
                                transform: `scale(${scaleFactor})`,
                                transformOrigin: 'top left',
                                width: DEFAULT_WIDGET_SIZE.width,
                                height: DEFAULT_WIDGET_SIZE.height,
                            }}
                        >
                            <Widget widgetDefinition={wd} />
                        </div>
                    </div>
                ))}
            </WidgetGridDiv>
            <div
                style={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    background: 'rgba(255,255,255,0.5)',
                }}
            >
                <WidgetHeaderButton onClick={openGroupViewCB} description={'Open group in detail view.'}>
                    <IoMdOpen size={20} />
                </WidgetHeaderButton>
            </div>
        </CellWrapper>
    );
};

export default WidgetPanelCell;
