import React from 'react';

interface INeuronSelectionContext {
    selectedNeurons: string[];
    toggleNeuronSelection: (nId: string) => void;
    isNeuronSelected: (nId: string) => boolean;
    resetNeuronSelection: () => void;

    // hoveredNeuron: string | null;
    // setHoveredNeuron: (nId: string | null) => void;
}

const defaultContext: INeuronSelectionContext = {
    selectedNeurons: [],
    toggleNeuronSelection: () => {
        // Initial value. Replaced by context provider.
    },
    isNeuronSelected: () => {
        // Initial value. Replaced by context provider.
        return false;
    },
    resetNeuronSelection: () => {
        // Initial value. Replaced by context provider.
    },

    // hoveredNeuron: null,
    // setHoveredNeuron: () => {
    //     // Initial value. Replaced by context provider.
    // },
};

const NeuronSelectionContext = React.createContext(defaultContext);

export default NeuronSelectionContext;
