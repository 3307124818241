import React from 'react';
import styled from 'styled-components';

const ContainerDiv = styled.div`
    max-width: 300px;
    background: rgba(255, 255, 255, 0.8);
    padding: 10px;
`;

const StyledHr = styled.hr`
    border-top: 1px solid var(--gray);
    margin-top: 2px;
    margin-bottom: 4px;
`;

const TitleSpan = styled.span`
    font-weight: bold;
    font-size: 1.4em;
`;

const ContentSpan = styled.span`
    color: var(--gray-dark);
`;

interface Props {
    title?: string | JSX.Element;
    content?: string | JSX.Element;
    style?: React.CSSProperties;
}

const NiceTooltipContent: React.FunctionComponent<Props> = ({ title, content, style }: Props) => {
    return (
        <ContainerDiv style={style}>
            {title && <TitleSpan>{title}</TitleSpan>}
            {title && content && <StyledHr />}
            {content && <ContentSpan>{content}</ContentSpan>}
        </ContainerDiv>
    );
};

export default NiceTooltipContent;
