import React from 'react';
import { LayerInnardComponentProps } from 'App/InspectionPanel/L2ArchitectureComponent/LayerComponent/LayerInnardComponent/LayerInnardComponentProps';
import InnardIcon from 'App/InspectionPanel/L2ArchitectureComponent/LayerComponent/LayerInnardComponent/InnardIcon/InnardIcon';
import { EntityType } from 'types/inspection-types/EntityType';
import InterestingnessContext from 'App/InterestingnessContext';

const LayerInnardComponent = (props: LayerInnardComponentProps) => {
    const { getInterestingness } = React.useContext(InterestingnessContext);

    const interestingness = getInterestingness(props.layerGraphNode);

    let component: JSX.Element;
    switch (props.layerGraphNode.type) {
        case EntityType.ACTIVATION_FN_LINEAR:
        case EntityType.ACTIVATION_FN_LRELU:
        case EntityType.ACTIVATION_FN_RELU:
        case EntityType.ACTIVATION_FN_SIGMOID:
        case EntityType.ACTIVATION_FN_SOFTMAX:
        case EntityType.ACTIVATION_FN_TANH:
        case EntityType.ACTIVATION_FN_UNKNOWN:
            component = <InnardIcon shape="circle" padding={30} iconWidth={60} iconHeight={60} {...props} />;
            break;

        case EntityType.ALGEBRAIC_OP_ADD:
        case EntityType.ALGEBRAIC_OP_CONV2D:
        case EntityType.ALGEBRAIC_OP_CONV2DTRANSPOSE:
        case EntityType.ALGEBRAIC_OP_MATMUL:
            component = <InnardIcon shape="circle" padding={10} iconWidth={30} iconHeight={30} {...props} />;
            break;

        case EntityType.VARIABLE_DENSE_BIAS:
            component = (
                <InnardIcon
                    shape="rect"
                    padding={20}
                    iconWidth={100}
                    iconHeight={100}
                    {...props}
                    interestingness={interestingness}
                />
            );
            break;
        case EntityType.VARIABLE_CONV2D_BIAS:
            component = (
                <InnardIcon
                    shape="rect"
                    padding={20}
                    iconWidth={100}
                    iconHeight={100}
                    {...props}
                    interestingness={interestingness}
                />
            );
            break;

        case EntityType.TENSOR_OP_INPUT:
        case EntityType.TENSOR_OP_CONCAT:
        case EntityType.TENSOR_OP_DROPOUT:
        case EntityType.TENSOR_OP_FLATTEN:
        case EntityType.TENSOR_OP_RESHAPE:
        case EntityType.TENSOR_OP_MAXPOOL2D:
            component = <InnardIcon shape="rect" padding={20} iconWidth={120} iconHeight={120} {...props} />;
            break;

        case EntityType.VARIABLE_DENSE_KERNEL:
            component = (
                <InnardIcon
                    shape="rect"
                    padding={20}
                    iconWidth={120}
                    iconHeight={120}
                    {...props}
                    interestingness={interestingness}
                />
            );
            break;
        case EntityType.VARIABLE_CONV2D_KERNEL:
            component = (
                <InnardIcon
                    shape="rect"
                    padding={20}
                    iconWidth={120}
                    iconHeight={120}
                    {...props}
                    interestingness={interestingness}
                />
            );
            break;

        default:
            component = <InnardIcon shape="rect" padding={20} iconWidth={120} iconHeight={120} {...props} />;
            break;
    }

    return <>{component}</>;
};

export default LayerInnardComponent;
