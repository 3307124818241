import React, { PropsWithChildren } from 'react';
import { useBrush, useLink } from 'tools/hooks/useLinkAndBrush';

interface LinkingProps<T> {
    eventIdentifier: string;
    linkedEntity: T | ((brushedEntity: T) => boolean);
    children: (isLinked?: boolean) => React.ReactNode;
}

const Linking = <T,>({ children, eventIdentifier, linkedEntity }: LinkingProps<T>) => {
    const [isLinked] = useLink<T>(eventIdentifier, linkedEntity);

    return <> {children(isLinked)}</>;
};

interface BrushingProps<T> {
    event: string;
    brushedEntity: T;
}

const Brushing = <T,>({ children, event, brushedEntity }: PropsWithChildren<BrushingProps<T>>) => {
    useBrush<T>(event, brushedEntity);

    return <>{children}</>;
};

export { Linking, Brushing };
