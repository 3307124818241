import React from 'react';
import { positionsToPathString } from 'tools/helpers';
import { Point2D } from 'types/Point2D';

interface Props {
    points: Point2D[];
    color: string;
}

const WhiskerComponent: React.FunctionComponent<Props> = ({ points, color }: Props) => {
    return (
        <g>
            <path
                d={positionsToPathString(points)}
                style={{
                    fill: 'none',
                    stroke: color,
                }}
            />
            <circle
                cx={points.slice(-1)[0].x}
                cy={points.slice(-1)[0].y}
                r={2}
                style={{
                    fill: color,
                    stroke: 'none',
                }}
            />
        </g>
    );
};

export default WhiskerComponent;
