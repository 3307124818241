import React from 'react';
import ModelContext from 'App/ModelContext';
import { Model } from 'types/nn-types/Model';

const ModelContextProvider = ({ children, model }: { children: React.ReactNode; model: Model }) => {
    return (
        <ModelContext.Provider
            value={{
                model,
            }}
        >
            {children}
        </ModelContext.Provider>
    );
};

export default ModelContextProvider;
