import React from 'react';
import { Group } from 'types/nn-types/Group';

interface IGroupsContext {
    groups: Group[];
}

const defaultContext: IGroupsContext = {
    groups: [],
};

const GroupsContext = React.createContext(defaultContext);

export default GroupsContext;
