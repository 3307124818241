export enum WidgetType {
    SINGLE_ENTITY_SINGLE_TIME = 0x01,
    SINGLE_ENTITY_MULTI_TIME,
    MULTI_ENTITY_SINGLE_TIME,
    MULTI_ENTITY_MULTI_TIME,

    INPUT_OUTPUT_COMPARISON_CLASSIFIER = 0x11,
    INPUT_OUTPUT_COMPARISON_AUTOENCODER,
    IMAGE_DATA_SAMPLES,
    ANNOTATION,
    SCATTERPLOT_2D,
    HISTOGRAM,
    MULTI_HISTOGRAM,
    FEATURE_HISTOGRAM,
    CONFUSION_MATRIX,
    VERBALIZATION,
}
