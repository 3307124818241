export enum LevelOfAbstraction {
    MULTI_MODEL = 0x3,
    SINGLE_MODEL = 0x2,
    LAYERS_UNITS = 0x1,
    WEIGHTS_NEURONS = 0x0,
}

export const LofAFriendlyNames: Record<number, string> = {
    0x3: 'Multi-Model',
    0x2: 'Single Model',
    0x1: 'Layers / Units',
    0x0: 'Weights / Neurons',
};

export const LevelsOfAbstraction: LevelOfAbstraction[] = [
    LevelOfAbstraction.MULTI_MODEL,
    LevelOfAbstraction.SINGLE_MODEL,
    LevelOfAbstraction.LAYERS_UNITS,
    LevelOfAbstraction.WEIGHTS_NEURONS,
];
