import React from 'react';
import { ModelGraphNode } from 'types/nn-types/ModelGraph';
import styled from 'styled-components';
import { reduceShapeToString } from 'tools/helpers';
import { EntityType } from 'types/inspection-types/EntityType';

const BackgroundRect = styled.rect`
    fill: rgba(255, 255, 255, 0.8);
`;

const StyledText = styled.text`
    text-anchor: middle;
    dominant-baseline: central;
    fill: var(--gray);
`;

interface Props {
    x: number;
    y: number;
    padding?: number;
    modelGraphNode: ModelGraphNode;
}

const WeightNetworkLabel: React.FunctionComponent<Props> = ({ x, y, padding = 5, modelGraphNode }: Props) => {
    const textRef = React.useRef<SVGTextElement>(null);
    const [textBBox, setTextBBox] = React.useState<undefined | DOMRect>();

    React.useEffect(() => {
        setTextBBox(textRef.current?.getBBox() ?? undefined);
    }, []);

    const kernelSize: number[] =
        modelGraphNode.type === EntityType.LAYER_CONV2D
            ? [
                  ...modelGraphNode.config['kernel_size'],
                  modelGraphNode.inputShape.slice(-1)[0],
                  modelGraphNode.outputShape.slice(-1)[0],
              ]
            : [modelGraphNode.inputShape.slice(-1)[0], modelGraphNode.outputShape.slice(-1)[0]];

    let height = 0;
    let width = 0;
    if (textBBox) {
        height = textBBox.height + 2 * padding;
        width = textBBox.width + 2 * padding;
    }

    return (
        <>
            {textBBox && <BackgroundRect x={x - width / 2} y={y - height / 2} width={width} height={height} />}
            <StyledText ref={textRef} x={x} y={y}>
                {reduceShapeToString(kernelSize)}
            </StyledText>
        </>
    );
};

export default WeightNetworkLabel;
