import styled from 'styled-components';

export const WidgetGridDiv = styled.div<{ $numWidgets: number }>`
    height: 100%;
    display: grid;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    grid-auto-flow: column dense;
    grid-template-rows: ${({ $numWidgets }) => ($numWidgets <= 1 ? '1fr' : '1fr 1fr')};
`;
