import React from 'react';
import { Group, GROUP_INSTANCE } from 'types/nn-types/Group';

interface IGroupContext {
    group: Group;
}

const defaultContext: IGroupContext = {
    group: GROUP_INSTANCE,
};

const GroupContext = React.createContext(defaultContext);

export default GroupContext;
