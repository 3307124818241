import React from 'react';
import { ZoomState } from 'types/ZoomState';
import { ProvidedZoom } from '@visx/zoom/lib/types';

interface IZoomStateContext {
    zoom: ProvidedZoom<SVGSVGElement> & ZoomState;
}

const defaultContext: IZoomStateContext = {
    zoom: {} as ProvidedZoom<SVGSVGElement> & ZoomState,
};

const ZoomStateContext = React.createContext(defaultContext);

export default ZoomStateContext;
