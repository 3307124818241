import { ReactComponent as ActivationLeakyRelu } from 'icons/layer-innards/activation_lrelu.svg';
import { ReactComponent as ActivationLinear } from 'icons/layer-innards/activation_linear.svg';
import { ReactComponent as ActivationRelu } from 'icons/layer-innards/activation_relu.svg';
import { ReactComponent as ActivationSigmoid } from 'icons/layer-innards/activation_sigmoid.svg';
import { ReactComponent as ActivationSoftmax } from 'icons/layer-innards/activation_softmax.svg';
import { ReactComponent as ActivationTanh } from 'icons/layer-innards/activation_tanh.svg';
import { ReactComponent as ActivationUnknown } from 'icons/layer-innards/activation_unknown.svg';
import { ReactComponent as Bias } from 'icons/layer-innards/bias.svg';
import { ReactComponent as Input } from 'icons/layer-innards/input.svg';
import { ReactComponent as KernelConv2D } from 'icons/layer-innards/kernel-conv2d.svg';
import { ReactComponent as KernelConv2D1x1 } from 'icons/layer-innards/kernel-conv2d_1x1.svg';
import { ReactComponent as KernelConv2D3x3 } from 'icons/layer-innards/kernel-conv2d_3x3.svg';
import { ReactComponent as KernelConv2D5x5 } from 'icons/layer-innards/kernel-conv2d_5x5.svg';
import { ReactComponent as KernelConv2D5x5x64 } from 'icons/layer-innards/kernel-conv2d_5x5_64.svg';
import { ReactComponent as KernelDense } from 'icons/layer-innards/kernel-dense.svg';
import { ReactComponent as OpAdd } from 'icons/layer-innards/op-add.svg';
import { ReactComponent as OpConcat } from 'icons/layer-innards/op-concat.svg';
import { ReactComponent as OpDropout } from 'icons/layer-innards/op-dropout.svg';
import { ReactComponent as OpFlatten } from 'icons/layer-innards/op-flatten.svg';
import { ReactComponent as OpMatmul } from 'icons/layer-innards/op-matmul.svg';
import { ReactComponent as OpMaxpool2D } from 'icons/layer-innards/op-maxpool2d.svg';
import { ReactComponent as OpAvgpool2D } from 'icons/layer-innards/op-avgpool2d.svg';
import { ReactComponent as OpConv2D } from 'icons/layer-innards/op-conv2d.svg';
import { ReactComponent as OpConv2DTranspose } from 'icons/layer-innards/op-conv2dtranspose.svg';
import { ReactComponent as OpReshape } from 'icons/layer-innards/op-reshape.svg';
import { ReactComponent as Misc } from 'icons/layer-innards/misc.svg';

const LayerInnardTypeIcons = {
    ActivationLeakyRelu,
    ActivationLinear,
    ActivationRelu,
    ActivationSigmoid,
    ActivationSoftmax,
    ActivationTanh,
    ActivationUnknown,
    Bias,
    Input,
    KernelConv2D,
    KernelConv2D1x1,
    KernelConv2D3x3,
    KernelConv2D5x5,
    KernelConv2D5x5x64,
    KernelDense,
    OpAdd,
    OpConcat,
    OpDropout,
    OpFlatten,
    OpMatmul,
    OpMaxpool2D,
    OpAvgpool2D,
    OpConv2D,
    OpConv2DTranspose,
    OpReshape,
    Misc,
};

export default LayerInnardTypeIcons;
