import names from './names.json';
import _ from 'lodash';

const ALL_NAMES = [...names.NAMES_MALE, ...names.NAMES_FEMALE];

/**
 * Samples randomly from a list of english first names.
 * The name lists are taken from "Moby Word Lists by Grady Ward".
 * See: https://www.gutenberg.org/ebooks/3201
 */
export default function getRandomName(): string {
    return _.sample(ALL_NAMES) as string;
}
