import React from 'react';
import { WidgetProps } from 'App/WidgetPanel/Widget';
import { scaleBand, scaleLinear } from '@visx/scale';
import { ParentSize } from '@visx/responsive';
import { Group } from '@visx/group';
import { AxisBottom } from '@visx/axis';
import { InnerVisContainer, OuterVisContainer } from 'App/WidgetPanel/Widgets/StyledContainers';
import AxisLabels from 'App/WidgetPanel/Widgets/AxisLabels';
import _ from 'lodash';
import { getNumberFormatter } from 'tools/helpers';
import DataBar from 'App/WidgetPanel/Widgets/MultiEntitySingleTimeWidget/DataBar';
import SelectionContext from 'App/SelectionContext';

interface DataEntity {
    entityId: string;
    color: string;
    value: number;
}

const DEFAULT_MARGIN = { top: 10, right: 10, bottom: 30, left: 10 };

const MultiEntitySingleTimeWidget = ({ widgetDefinition, margin = DEFAULT_MARGIN }: WidgetProps) => {
    const { selectedEntities } = React.useContext(SelectionContext);

    const attributes = Object.keys(widgetDefinition.dataEntities[0].data[0]);

    const data: Array<DataEntity> = widgetDefinition.dataEntities
        .map((e) => ({
            entityId: e.entity.id,
            color: e.color,
            value: e.data[0][attributes[0]] as number,
        }))
        .sort((e1, e2) => (e1.value as number) - (e2.value as number));

    const getXScale = (xMax: number) =>
        scaleLinear({
            domain: [0, Math.max(...data.map((d) => d.value as number))],
            range: [0, xMax],
        });

    const getYScale = (yMax: number) =>
        scaleBand({
            domain: data.map((d) => d.entityId),
            range: [yMax, 0],
            padding: 0.1,
        });

    const unit = widgetDefinition.dataEntities[0].unit;

    return (
        <>
            <OuterVisContainer>
                <InnerVisContainer>
                    <ParentSize debounceTime={10}>
                        {({ width: visWidth, height: visHeight }) => {
                            const xMax = visWidth - margin.left - margin.right;
                            const yMax = visHeight - margin.top - margin.bottom;
                            const xScale = getXScale(xMax);
                            const yScale = getYScale(yMax);

                            return (
                                <>
                                    <svg width={visWidth} height={visHeight} style={{ background: '#fff' }}>
                                        <Group left={margin.left} top={margin.top}>
                                            <AxisBottom
                                                tickFormat={getNumberFormatter(3)}
                                                top={yMax}
                                                scale={xScale}
                                                numTicks={5}
                                            />
                                            {data.map((d) => {
                                                const { entityId, value } = d;
                                                const barWidth = xScale(value) ?? 0;
                                                const barHeight = yScale.bandwidth() ?? 0;
                                                const barX = xScale(0) ?? 0;
                                                const barY = yScale(entityId) ?? 0;
                                                const color =
                                                    selectedEntities.length === 0 ||
                                                    selectedEntities.find((id) => id === entityId)
                                                        ? d.color
                                                        : 'rgba(200, 200, 200)';

                                                return (
                                                    <DataBar
                                                        key={entityId}
                                                        entityId={entityId}
                                                        color={color}
                                                        x={barX}
                                                        y={barY}
                                                        width={barWidth}
                                                        height={barHeight}
                                                        value={value}
                                                        unit={unit}
                                                    />
                                                );
                                            })}

                                            <AxisLabels
                                                labelX={_.startCase(attributes[0])}
                                                xMax={xMax}
                                                yMax={yMax}
                                                markIncompatibleScales={false}
                                            />
                                        </Group>
                                    </svg>
                                </>
                            );
                        }}
                    </ParentSize>
                </InnerVisContainer>
            </OuterVisContainer>
        </>
    );
};

export default MultiEntitySingleTimeWidget;
