import React from 'react';
import { WidgetDefinitionWithId } from 'types/inspection-types/WidgetDefinition';
import { FadeLoader } from 'react-spinners';
import styled from 'styled-components';

export const Container = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface Props {
    wd: WidgetDefinitionWithId;
}

const LoadingWidget: React.FunctionComponent<Props> = ({ wd }: Props) => {
    return (
        <>
            <Container>
                <FadeLoader color={'#666'} />
            </Container>
        </>
    );
};

export default LoadingWidget;
