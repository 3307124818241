import React, { useCallback, useState } from 'react';
import { Bar } from '@visx/shape';
import { Text } from '@visx/text';
import * as mathjs from 'mathjs';
import { unitToSymbol } from 'tools/helpers';
import SelectionContext from 'App/SelectionContext';
import { darker } from 'tools/colors';
import { Brushing, Linking } from 'App/LinkingAndBrushing';

interface Props {
    entityId: string;
    color: string;
    x: number;
    y: number;
    width: number;
    height: number;
    value: number;
    unit?: string;
}

const DataBar: React.FunctionComponent<Props> = ({ x, y, width, height, entityId, color, value, unit }: Props) => {
    const { toggleSelection } = React.useContext(SelectionContext);
    const [hovered, setHovered] = useState(false);

    const onEntityClickHandler = useCallback(() => toggleSelection(entityId), [entityId, toggleSelection]);

    return (
        <Brushing event={'widget-hovered'} brushedEntity={hovered ? entityId : undefined}>
            <Linking eventIdentifier={'entity-hovered'} linkedEntity={entityId}>
                {(isLinked) => (
                    <g
                        onMouseOver={(e) => {
                            setHovered(true);
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        onMouseLeave={() => setHovered(false)}
                        onClick={onEntityClickHandler}
                    >
                        <Bar
                            x={x}
                            y={y}
                            width={width}
                            height={height}
                            stroke={isLinked ? darker(color) : undefined}
                            strokeWidth={2}
                            fill={color}
                        />
                        <Text verticalAnchor="middle" y={y + height / 2} x={x + 5}>
                            {unit
                                ? mathjs.unit(value, unitToSymbol(unit)).format({
                                      notation: 'fixed',
                                      precision: 2,
                                  })
                                : value}
                        </Text>
                    </g>
                )}
            </Linking>
        </Brushing>
    );
};

export default DataBar;
