import React, { memo, RefObject } from 'react';
import { WidgetProps } from 'App/WidgetPanel/Widget';
import { InnerVisContainer, OuterVisContainer } from 'App/WidgetPanel/Widgets/StyledContainers';
import ClassSelectionContext from 'App/ClassSelectionContext';
import {
    SampleContainerDiv,
    SamplePairDiv,
    StyledImg,
    StyledTinyBarChart,
    WrapperDiv,
} from 'App/WidgetPanel/Widgets/ImageInputOutputWidget/styled';
import { useMeasure } from 'react-use';
import { acyclicInterpolateSinebow, ordinalColorscale } from 'tools/colors';
import _ from 'lodash';
import { isNumberArray } from 'types/inspection-types/DataArray';

const PredictionLabelComparisonWidget = ({ widgetDefinition }: WidgetProps) => {
    const { isClassSelected } = React.useContext(ClassSelectionContext);
    let { data } = widgetDefinition.dataEntities[0];

    data = data.filter((d) => isClassSelected(d['yLabel'] as number | string));
    const categoricalOutputSize = isNumberArray(data[0]['yPredictionCategorical'])
        ? data[0]['yPredictionCategorical'].length
        : 0;

    // TODO: This could be solved more efficient with CSS media queries...
    const [ref, { width }] = useMeasure();
    const expand = width > 300;

    const barChartColorScale = ordinalColorscale(_.range(categoricalOutputSize), acyclicInterpolateSinebow);

    return (
        <>
            <OuterVisContainer ref={ref as unknown as RefObject<HTMLDivElement>}>
                <InnerVisContainer>
                    <WrapperDiv>
                        {data.map((d) => {
                            const label = d['yLabel'] as number | string;
                            const prediction = d['yPrediction'] as number | string;

                            return (
                                <SamplePairDiv
                                    key={d['sampleId'] as number | string}
                                    style={{
                                        color: label === prediction ? 'var(--green)' : 'var(--red)',
                                    }}
                                >
                                    <SampleContainerDiv>
                                        <StyledImg src={d['xImage'] as string} width={expand ? 48 : 32} />x
                                    </SampleContainerDiv>
                                    <SampleContainerDiv>
                                        <StyledTinyBarChart
                                            width={expand ? 64 : 32}
                                            height={expand ? 48 : 32}
                                            data={d['yLabelCategorical'] as number[]}
                                            colorScale={barChartColorScale}
                                            maxValue={1}
                                        />
                                        Lbl: {label}
                                    </SampleContainerDiv>
                                    <SampleContainerDiv>
                                        <StyledTinyBarChart
                                            width={expand ? 64 : 32}
                                            height={expand ? 48 : 32}
                                            data={d['yPredictionCategorical'] as number[]}
                                            colorScale={barChartColorScale}
                                            maxValue={1}
                                        />
                                        Pred: {prediction}
                                    </SampleContainerDiv>
                                </SamplePairDiv>
                            );
                        })}
                    </WrapperDiv>
                </InnerVisContainer>
            </OuterVisContainer>
        </>
    );
};

export default memo(PredictionLabelComparisonWidget);
