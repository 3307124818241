import { interpolateRgbBasis } from 'd3-interpolate';
import { scaleLinear } from 'd3-scale';

const colorScale = (minimum: number, maximum: number, interpolator: (value: number) => string) => {
    if (minimum < 0) {
        // To avoid a bias with negative values, we mirror the value range at 0.
        // This way, the midpoint resides at 0 and negative values are filled with a low-end color.
        // Otherwise, it would not be possible for the user to determine which values are truly negative.
        minimum = -Math.max(Math.abs(minimum), maximum);
        maximum = Math.abs(minimum);
    }

    const normalizationScale = scaleLinear().domain([minimum, maximum]);

    // 0.5 is the default as it returns the neutral color between 0 and 1.
    return (value: number) => interpolator(normalizationScale(value) ?? 0.5);
};

const nodeInterpolator = interpolateRgbBasis([
    '#10194d',
    '#163771',
    '#1c5796',
    '#397aa8',
    '#579eb9',
    '#89c0c4',
    '#bce2cf',
    '#ffffe0',
    '#fad4ac',
    '#f0a882',
    '#e47961',
    '#c65154',
    '#a52747',
    '#751232',
    '#4a001e',
]);
export const getNodeColorScale = (minimumContribution: number, maximumContribution: number) =>
    colorScale(minimumContribution, maximumContribution, nodeInterpolator);

const weightNetworkInterpolator = interpolateRgbBasis([
    // '#002c2d',
    // '#00494b',
    // '#076769',
    // '#208288',
    // '#3ea8a6',
    // '#76c7be',
    // '#bbe4d1',
    '#ffffe0',
    '#fed693',
    '#f5ad52',
    '#dd8629',
    '#bd651a',
    '#9c4511',
    '#79260b',
    '#580000',
]);
export const getWeightNetworkColorScale = (minimumWeight: number, maximumWeight: number) =>
    colorScale(minimumWeight, maximumWeight, weightNetworkInterpolator);
