import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import usePortal from 'react-useportal';
import { useKeyPressEvent } from 'react-use';

const GroupOverlayDiv = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10000;

    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
`;

interface Props {
    closeHandler: () => void;
}

const PageOverlay: React.FunctionComponent<PropsWithChildren<Props>> = ({
    closeHandler,
    children,
}: PropsWithChildren<Props>) => {
    const { Portal } = usePortal();
    useKeyPressEvent('Escape', closeHandler);

    return (
        <Portal>
            <GroupOverlayDiv onClick={closeHandler}> {children}</GroupOverlayDiv>
        </Portal>
    );
};

export default PageOverlay;
