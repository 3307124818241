import React, { FunctionComponent } from 'react';
import { WidgetProps } from '../Widget';
import { scaleLinear } from '@visx/scale';
import { InnerVisContainer, OuterVisContainer } from './StyledContainers';
import { ParentSize } from '@visx/responsive';
import { Group } from '@visx/group';
import { DataRow, DataValue } from 'types/inspection-types/DataArray';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { getNumberFormatter } from 'tools/helpers';
import { Bar } from '@visx/shape';

const DEFAULT_MARGIN = { top: 14, right: 12, bottom: 30, left: 40 };

/**
 * Gets the value of an attribute across all rows.
 * @param histogram an array of DataRows (i.e., a DataTable)
 * @param attribute the key of the desired attribute
 * @return an array of all values of the desired attribute
 */
const valuesAccessor = (histogram: DataRow[], attribute: string): DataValue[] => histogram.map((row) => row[attribute]);

const MultiHistogramWidget: FunctionComponent<WidgetProps> = ({
    widgetDefinition,
    margin = DEFAULT_MARGIN,
}: WidgetProps) => {
    const { data, color } = widgetDefinition.dataEntities[0];

    const maxCount = Math.max(...(valuesAccessor(data, 'count') as number[]));
    const minStartBoundary = Math.min(...(valuesAccessor(data, 'start') as number[]));
    const maxEndBoundary = Math.max(...(valuesAccessor(data, 'end') as number[]));

    return (
        <>
            <OuterVisContainer>
                <InnerVisContainer>
                    <ParentSize debounceTime={10}>
                        {({ width: visWidth, height: visHeight }) => {
                            const xExtend = visWidth - margin.left - margin.right;
                            const yExtend = visHeight - margin.top - margin.bottom;

                            const scaleX = scaleLinear<number>({
                                range: [0, xExtend],
                                domain: [minStartBoundary, maxEndBoundary],
                            });

                            const scaleY = scaleLinear({
                                domain: [0, maxCount],
                                range: [0, yExtend],
                            });

                            const scaleYAxis = scaleLinear({
                                domain: [0, maxCount],
                                range: [yExtend, 0],
                            });

                            return (
                                <svg width={visWidth} height={visHeight} style={{ background: '#fff' }}>
                                    <Group top={margin.top} left={margin.left}>
                                        <AxisLeft tickFormat={getNumberFormatter(3)} scale={scaleYAxis} numTicks={5} />
                                        <AxisBottom tickFormat={getNumberFormatter(3)} top={yExtend} scale={scaleX} />
                                        {data.map((d, idx) => {
                                            const xLeft = scaleX(d['start'] as number) ?? 0;
                                            const xRight = scaleX(d['end'] as number) ?? 0;
                                            const barWidth = xRight - xLeft;
                                            const barHeight = scaleY(d['count'] as number) ?? 0;
                                            const barX = scaleX(d['start'] as number) ?? 0;
                                            const barY = yExtend - barHeight;
                                            return (
                                                <Bar
                                                    key={`bar-${idx}`}
                                                    x={barX}
                                                    y={barY}
                                                    width={barWidth}
                                                    height={barHeight}
                                                    fill={color}
                                                    opacity={0.8}
                                                />
                                            );
                                        })}
                                        <AxisLeft scale={scaleY} numTicks={0} left={scaleX(0)} />
                                    </Group>
                                </svg>
                            );
                        }}
                    </ParentSize>
                </InnerVisContainer>
            </OuterVisContainer>
        </>
    );
};

export default React.memo(MultiHistogramWidget);
