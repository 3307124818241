import { interpolateCubehelix } from 'd3-interpolate';
import { scaleLinear } from 'd3-scale';

export const schemeModelColors: string[] = [
    '#9F608E',
    '#517EAE',
    '#58A7A1',
    '#5FAB54',
    '#E8BA17',
    '#F07E0F',
    '#D02F31',
];

export const interpolateModelColors: (t: number) => string = (t: number) =>
    scaleLinear<string>()
        .domain(schemeModelColors.map((_, idx) => idx / (schemeModelColors.length - 1)))
        .range(schemeModelColors)
        .interpolate(interpolateCubehelix)(t) ?? '#000';
