import React, { useContext } from 'react';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';
import WidgetDragObject from 'types/dnd/WidgetDragObject';
import DNDType from 'types/dnd/DNDType';
import WidgetContext from 'App/WidgetContext';
import { CSSTransition } from 'react-transition-group';
import './NewWidgetGroupDropTarget.css';

const AddWidgetSymbolWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid var(--gray);
    background: var(--light);
    width: 50px;
    aspect-ratio: 1 / 1;
    border-radius: 5px;
    box-shadow: 0 0 5px 5px var(--gray-transparent);

    position: absolute;
    top: 20px;
    right: 20px;
`;

const AddWidgetSymbol = styled.div`
    &::after {
        content: '+';
        font-size: 30px;
        font-family: sans-serif;
        font-weight: bold;
        color: var(--gray);
    }
`;

interface Props {
    visible: boolean;
}

const NewWidgetGroupDropTarget: React.FunctionComponent<Props> = ({ visible }: Props) => {
    const { moveWidget } = useContext(WidgetContext);

    const [, dropRef] = useDrop<WidgetDragObject, void, void>({
        accept: DNDType.WIDGET,
        drop(item) {
            moveWidget(item.widgetId, undefined);
        },
    });

    return (
        <CSSTransition in={visible} timeout={350} classNames="droptarget" unmountOnExit>
            <AddWidgetSymbolWrapper ref={dropRef}>
                <AddWidgetSymbol />
            </AddWidgetSymbolWrapper>
        </CSSTransition>
    );
};

export default NewWidgetGroupDropTarget;
