import React, { useEffect, useState } from 'react';
import {
    InterestingnessProps,
    LayerInnardComponentProps,
    LayoutProps,
} from 'App/InspectionPanel/L2ArchitectureComponent/LayerComponent/LayerInnardComponent/LayerInnardComponentProps';
import { BoundingShape } from 'App/InspectionPanel/L2ArchitectureComponent/LayerComponent/LayerInnardComponent/StyledBoundingShapes';
import BoxedIcon from 'App/InspectionPanel/L2ArchitectureComponent/LayerComponent/LayerInnardComponent/InnardIcon/BoxedIcon';
import { useInterestingnessSVGFilter } from 'tools/hooks/useSVGFilter';

type Size = { height: number; width: number };

const InnardIcon = ({
    padding,
    shape,
    onSizeChange,
    interestingness,
    ...props
}: {
    shape: 'circle' | 'rect';
} & LayerInnardComponentProps &
    LayoutProps &
    InterestingnessProps) => {
    const [contentSize, setContentSize] = useState<Size>({ height: 0, width: 0 });

    const [filterId, filterElement] = useInterestingnessSVGFilter(interestingness ?? 0);

    useEffect(() => {
        onSizeChange(contentSize.width + 2 * padding, contentSize.height + 2 * padding);
    }, [contentSize, padding, onSizeChange]);

    return (
        <>
            {filterElement}
            <BoxedIcon
                contentSize={contentSize}
                setContentSize={setContentSize}
                onSizeChange={onSizeChange}
                padding={padding}
                {...props}
            >
                {shape === 'circle' ? (
                    <BoundingShape
                        as="circle"
                        cx={contentSize.width / 2}
                        cy={contentSize.height / 2}
                        r={(contentSize.width + 2 * padding) / 2}
                        filter={interestingness ? `url(#${filterId})` : undefined}
                    />
                ) : (
                    <BoundingShape
                        as="rect"
                        x={-padding}
                        y={-padding}
                        width={contentSize.width + 2 * padding}
                        height={contentSize.height + 2 * padding}
                        filter={interestingness ? `url(#${filterId})` : undefined}
                    />
                )}
            </BoxedIcon>
        </>
    );
};

export default InnardIcon;
