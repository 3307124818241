import React from 'react';

interface IClassSelectionContext {
    selectedClasses: Array<number | string>;
    allClasses: Array<number | string>;
    toggleClassSelection: (cls: number | string) => void;
    isClassSelected: (cls: number | string) => boolean;
    isSelectionEmpty: () => boolean;
}

const defaultContext: IClassSelectionContext = {
    selectedClasses: [],
    allClasses: [],
    toggleClassSelection: () => {
        // Initial value. Replaced by context provider.
    },
    isClassSelected: () => {
        // Initial value. Replaced by context provider.
        return false;
    },
    isSelectionEmpty: () => {
        // Initial value. Replaced by context provider.
        return false;
    },
};

const ClassSelectionContext = React.createContext(defaultContext);

export default ClassSelectionContext;
