import React from 'react';
import { Tool } from 'types/inspection-types/Tool';

export interface IToolContext {
    activeTool: Tool | null;
    setActiveTool: (tool: Tool) => void;
    unsetActiveTool: () => void;
}

const defaultContext: IToolContext = {
    activeTool: null,
    setActiveTool: () => {
        // Initial value. Replaced by context provider.
    },
    unsetActiveTool: () => {
        // Initial value. Replaced by context provider.
    },
};

const ToolContext = React.createContext(defaultContext);

export default ToolContext;
