import React, { useCallback, useState } from 'react';
import { line } from '@visx/shape';
import { curveBasis } from '@visx/curve';
import { Entity } from 'types/inspection-types/Entity';
import { Brushing, Linking } from 'App/LinkingAndBrushing';
import useUUID from 'tools/hooks/useUUID';
import SelectionContext from 'App/SelectionContext';
// import {line} from "d3-shape";

interface Props {
    entity: Entity;
    data: number[][];
    color: string;
}

const DataLine: React.FunctionComponent<Props> = ({ entity, data, color }: Props) => {
    const { toggleSelection } = React.useContext(SelectionContext);

    const [hovered, setHovered] = useState(false);
    const uuid = useUUID();

    const onEntityClickHandler = useCallback(() => toggleSelection(entity.id), [entity.id, toggleSelection]);

    const lineGenerator = line<number[]>()
        .curve(curveBasis)
        .x((d) => d[0])
        .y((d) => d[1]);

    const svgPathD = lineGenerator(data) ?? undefined;

    return (
        <Brushing event={'widget-hovered'} brushedEntity={hovered ? entity.id : undefined}>
            <Linking eventIdentifier={'entity-hovered'} linkedEntity={entity.id}>
                {(isLinked) => (
                    <>
                        <path
                            key={`line-${uuid}`}
                            d={svgPathD}
                            stroke={color}
                            fill={'none'}
                            strokeWidth={isLinked ? 5 : 2}
                            strokeOpacity={0.8}
                        />
                        <path
                            /* Invisible Event Catcher */
                            onMouseOver={(e) => {
                                setHovered(true);
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                            onMouseLeave={() => setHovered(false)}
                            onClick={onEntityClickHandler}
                            key={`line-${entity.id}`}
                            d={svgPathD}
                            stroke={'rgba(0,0,0,0)'}
                            fill={'none'}
                            strokeWidth={5}
                        />
                    </>
                )}
            </Linking>
        </Brushing>
    );
};

export default DataLine;
