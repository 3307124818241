import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import React, { Dispatch, ReactElement, SetStateAction } from 'react';

export enum Side {
    LEFT,
    RIGHT,
}

const CardFlap = ({
    backgroundColor = 'rgb(247, 247, 247)',
    icon,
    isCardCollapsed,
    marginLeft = -1,
    marginRight = -1,
    marginTop = 0,
    setIsCardCollapsed,
    side,
}: {
    backgroundColor?: string;
    icon: ReactElement;
    isCardCollapsed: boolean;
    marginLeft?: number;
    marginRight?: number;
    marginTop?: number;
    setIsCardCollapsed: Dispatch<SetStateAction<boolean>>;
    side: Side;
}) => {
    const border = '1px solid rgba(0, 0, 0, 0.125)';

    let collapseIcon;
    if (side === Side.LEFT) {
        collapseIcon = isCardCollapsed ? (
            <BiChevronLeft style={{ marginLeft: 5, marginRight: 3 }} />
        ) : (
            <BiChevronRight />
        );
    } else {
        collapseIcon = isCardCollapsed ? (
            <BiChevronRight style={{ marginLeft: 5, marginRight: 3 }} />
        ) : (
            <BiChevronLeft />
        );
    }

    return (
        <div
            onClick={() => {
                setIsCardCollapsed(!isCardCollapsed);
            }}
            style={{
                alignItems: 'center',
                backgroundColor,
                borderBottom: border,
                borderLeft: side === Side.LEFT ? border : 'unset',
                borderRight: side === Side.RIGHT ? border : 'unset',
                borderTop: border,
                boxShadow: '0 0 10px var(--gray)',
                clipPath: side === Side.LEFT ? 'inset(-10px 0px -10px -10px)' : 'inset(-10px -10px -10px 0px)',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                marginLeft,
                marginRight,
                marginTop,
                maxHeight: 48.5,
                minHeight: 48.5,
                minWidth: 25,
                zIndex: 1,
            }}
        >
            {isCardCollapsed ? icon : null}
            {collapseIcon}
        </div>
    );
};

export default CardFlap;
