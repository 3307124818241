import React, { FunctionComponent } from 'react';
import { useTooltipInPortal } from '@visx/tooltip';
import LayerComponent from 'App/InspectionPanel/L2ArchitectureComponent/LayerComponent/LayerComponent';
import { ModelGraphNode } from 'types/nn-types/ModelGraph';

interface Props {
    x: number;
    y: number;
    hoveredLayerNode: ModelGraphNode;
}

const LayerLens: FunctionComponent<Props> = ({ x, y, hoveredLayerNode }: Props) => {
    const [l2LayerSize, setL2LayerComponentSize] = React.useState<{ width: number; height: number }>({
        width: 0,
        height: 0,
    });

    const { TooltipInPortal } = useTooltipInPortal({
        // use TooltipWithBounds
        detectBounds: true,
        // when tooltip containers are scrolled, this will correctly update the Tooltip position
        scroll: true,
    });

    const onSizeChangeHandler = React.useCallback((newWidth: number, newHeight: number) => {
        setL2LayerComponentSize({ width: newWidth, height: newHeight });
    }, []);

    // Only re-render SVG when l2LayerSize changes
    const layerSVG = React.useMemo(() => {
        return (
            <svg width={l2LayerSize?.width ?? 0} height={l2LayerSize?.height ?? 0}>
                <LayerComponent modelGraphNode={hoveredLayerNode} x={0} y={0} onSizeChange={onSizeChangeHandler} />
            </svg>
        );
    }, [hoveredLayerNode, onSizeChangeHandler, l2LayerSize]);

    return (
        <TooltipInPortal className={'lens-tooltip'} left={x + 2} top={y + 2}>
            {layerSVG}
        </TooltipInPortal>
    );
};

// Only re-render, when props actually change
export default React.memo(LayerLens);
