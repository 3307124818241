import React, { useCallback, useReducer } from 'react';
import { Form } from 'react-bootstrap';
import { WidgetDefinitionWithId } from 'types/inspection-types/WidgetDefinition';
import mergeCompatibleWidgets from 'App/WidgetPanel/merge-compatible-widgets';
import equallyScaleCompatibleWidgets from 'App/WidgetPanel/equally-scale-compatible-widgets';
import Widget from 'App/WidgetPanel/Widget';
import { WidgetGridDiv } from 'App/WidgetPanel/styled';
import styled from 'styled-components';
import PageOverlay from 'App/PageOverlay';
import { scaleLog } from '@visx/scale';

const DEFAULT_WIDGET_SIZE = {
    width: 480,
    height: 640,
};

const GroupHeaderDiv = styled.div`
    color: white;
`;

const GroupBodyDiv = styled.div``;

const OverflowMarginDiv = styled.div`
    margin: 10px;
    display: inline-block;
`;

const TitleDiv = styled.div`
    font-size: 16px;
    font-weight: bold;
`;

const initialCheckboxState = {
    useCommonScale: false,
    mergeSameType: false,
    useLogScale: false,
};

const checkboxStateReducer = (state: typeof initialCheckboxState, action: { type: string; payload: boolean }) => {
    switch (action.type) {
        case 'useCommonScale':
            return { ...state, useCommonScale: action.payload };
        case 'mergeSameType':
            return { ...state, mergeSameType: action.payload };
        case 'useLogScale':
            return { ...state, useLogScale: action.payload };
        default:
            return state;
    }
};

interface Props {
    title: string;
    widgetDefinitions: WidgetDefinitionWithId[];
    closeHandler: () => void;
}

const WidgetPanelGroupView: React.FunctionComponent<Props> = ({ title, widgetDefinitions, closeHandler }: Props) => {
    const [checkboxState, checkboxDispatch] = useReducer(checkboxStateReducer, initialCheckboxState);

    let metaWidgets: WidgetDefinitionWithId[] = widgetDefinitions;
    // First scale compatible widgets equally
    if (checkboxState.useCommonScale) {
        metaWidgets = equallyScaleCompatibleWidgets(metaWidgets);
    }
    // Then merge compatible widgets (order is important here since scaling is more generically applicable!)
    if (checkboxState.mergeSameType) {
        metaWidgets = mergeCompatibleWidgets(metaWidgets);
    }
    // Then merge compatible widgets (order is important here since scaling is more generically applicable!)
    if (checkboxState.useLogScale) {
        metaWidgets = metaWidgets.map((w) => ({
            ...w,
            scaleCreatorFn: scaleLog,
        }));
    }

    const catchEvent = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
    }, []);

    return (
        <PageOverlay closeHandler={closeHandler}>
            <GroupHeaderDiv onClick={catchEvent}>
                <div style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: '1 0 0px', margin: 0 }}>
                        <TitleDiv>{title}</TitleDiv>
                        <div>
                            <fieldset>
                                <Form.Check
                                    inline
                                    label={'Use Common Scale'}
                                    type={'checkbox'}
                                    id={'checkbox-use-common-scale'}
                                    className={'no-select'}
                                    onChange={(e) =>
                                        checkboxDispatch({
                                            type: 'useCommonScale',
                                            payload: !checkboxState.useCommonScale,
                                        })
                                    }
                                    checked={checkboxState.useCommonScale}
                                />
                                <Form.Check
                                    inline
                                    label={'Merge Compatible Widgets'}
                                    type={'checkbox'}
                                    id={'checkbox-merge-same-type'}
                                    className={'no-select'}
                                    onChange={(e) =>
                                        checkboxDispatch({
                                            type: 'mergeSameType',
                                            payload: !checkboxState.mergeSameType,
                                        })
                                    }
                                    checked={checkboxState.mergeSameType}
                                />
                                <Form.Check
                                    inline
                                    label={'Use Log Scale'}
                                    type={'checkbox'}
                                    id={'checkbox-use-log-scale'}
                                    className={'no-select'}
                                    onChange={(e) =>
                                        checkboxDispatch({
                                            type: 'useLogScale',
                                            payload: !checkboxState.useLogScale,
                                        })
                                    }
                                    checked={checkboxState.useLogScale}
                                />
                            </fieldset>
                        </div>
                    </div>
                </div>
            </GroupHeaderDiv>
            <GroupBodyDiv onClick={catchEvent}>
                <OverflowMarginDiv>
                    <WidgetGridDiv $numWidgets={1}>
                        {metaWidgets.map((wd) => (
                            <div
                                key={wd.widgetId}
                                style={{
                                    width: DEFAULT_WIDGET_SIZE.width,
                                    height: DEFAULT_WIDGET_SIZE.height,
                                }}
                            >
                                <Widget widgetDefinition={wd} />
                            </div>
                        ))}
                    </WidgetGridDiv>
                </OverflowMarginDiv>
            </GroupBodyDiv>
        </PageOverlay>
    );
};
export default WidgetPanelGroupView;
