import React from 'react';
import GroupContext from 'App/GroupContext';
import { Group } from 'types/nn-types/Group';

const GroupContextProvider = ({ children, group }: { children: React.ReactNode; group: Group }) => {
    return (
        <GroupContext.Provider
            value={{
                group,
            }}
        >
            {children}
        </GroupContext.Provider>
    );
};

export default GroupContextProvider;
