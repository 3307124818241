import { WidgetDefinitionWithId } from 'types/inspection-types/WidgetDefinition';
import { WidgetType } from 'types/inspection-types/WidgetType';
import * as uuid from 'uuid';

export default function mergeCompatibleWidgets(widgets: WidgetDefinitionWithId[]): WidgetDefinitionWithId[] {
    const metaWidgets: WidgetDefinitionWithId[] = [];
    const types = {} as Record<string, WidgetDefinitionWithId[]>;

    widgets.forEach((w) => {
        const typeHash = `${w.widgetType}-${w.tool.id}`;

        if (!types.hasOwnProperty(typeHash)) {
            types[typeHash] = [];
        }

        types[typeHash].push(w);
    });

    Object.values(types).forEach((compatibleGroup) => {
        if (compatibleGroup[0].widgetType === WidgetType.SINGLE_ENTITY_MULTI_TIME) {
            // If the entities in this group are SINGLE_ENTITY_MULTI_TIME, they can be merged
            const mw: WidgetDefinitionWithId = {
                ...compatibleGroup[0],
            };

            mw.widgetId = `META-${uuid.v4()}`;
            mw.dataEntities = compatibleGroup.map((wd) => wd.dataEntities[0]);

            metaWidgets.push(mw);
        } else {
            // If the entities in this group can not be meaningfully merged, just append the single widgets
            metaWidgets.push(...compatibleGroup);
        }
    });

    return metaWidgets;
}
