import React from 'react';

export const schemeLineStyles: React.CSSProperties[] = [
    {
        strokeLinecap: 'butt',
        strokeDasharray: 'none',
    },
    {
        strokeLinecap: 'round',
        strokeDasharray: '0 5',
    },
    {
        strokeLinecap: 'butt',
        strokeDasharray: '4 4',
    },
    {
        strokeLinecap: 'butt',
        strokeDasharray: '4 2 1 2',
    },
];
