import styled from 'styled-components';
import { ButtonGroup } from 'react-bootstrap';

export const OuterVisContainer = styled.div`
    flex-grow: 1;

    display: flex;
    position: relative;
`;

export const InnerVisContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

export const LegendContainer = styled.div`
    flex-grow: 0;
    margin-top: 5px;
    font-size: 14px;
    line-height: 20px;
`;

export const AttributeSelectionButtonGroup = styled(ButtonGroup)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & > button {
        padding: 0 4px !important;
    }
`;
