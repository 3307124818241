import React, { memo, RefObject } from 'react';
import { WidgetProps } from 'App/WidgetPanel/Widget';
import { InnerVisContainer, OuterVisContainer } from 'App/WidgetPanel/Widgets/StyledContainers';
import ClassSelectionContext from 'App/ClassSelectionContext';
import {
    SampleContainerDiv,
    SamplePairDiv,
    StyledImg,
    WrapperDiv,
} from 'App/WidgetPanel/Widgets/ImageInputOutputWidget/styled';
import { useMeasure } from 'react-use';

const InputOutputAutoencoderWidget = ({ widgetDefinition }: WidgetProps) => {
    const { isClassSelected } = React.useContext(ClassSelectionContext);
    let { data } = widgetDefinition.dataEntities[0];

    data = data.filter((d) => isClassSelected(d['yLabel'] as number | string));

    // TODO: This could be solved more efficient with CSS media queries...
    const [ref, { width }] = useMeasure();
    const expand = width > 300;

    return (
        <>
            <OuterVisContainer ref={ref as unknown as RefObject<HTMLDivElement>}>
                <InnerVisContainer>
                    <WrapperDiv>
                        {data.map((d) => (
                            <SamplePairDiv key={d['sampleId'] as number | string}>
                                <SampleContainerDiv>
                                    <StyledImg src={d['yTarget'] as string} width={expand ? 48 : 32} />
                                    In
                                </SampleContainerDiv>
                                <SampleContainerDiv>
                                    <StyledImg src={d['yPrediction'] as string} width={expand ? 48 : 32} />
                                    Rec
                                </SampleContainerDiv>
                            </SamplePairDiv>
                        ))}
                    </WrapperDiv>
                </InnerVisContainer>
            </OuterVisContainer>
        </>
    );
};

export default memo(InputOutputAutoencoderWidget);
