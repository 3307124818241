import React from 'react';
import ZoomStateContext from 'App/InspectionPanel/ZoomStateContext';
import { ProvidedZoom } from '@visx/zoom/lib/types';
import { ZoomState } from 'types/ZoomState';

const ZoomStateContextProvider = ({
    children,
    zoom,
}: {
    children: React.ReactNode;
    zoom: ProvidedZoom<SVGSVGElement> & ZoomState;
}) => {
    return <ZoomStateContext.Provider value={{ zoom }}>{children}</ZoomStateContext.Provider>;
};

export default ZoomStateContextProvider;
