import React, { FunctionComponent, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { darker } from 'tools/colors';
import useUUID from 'tools/hooks/useUUID';

const StyledButton = styled(Button)`
    padding: 4px;
    border: none;
`;

interface Props {
    baseColor?: string;
    onClick?: (e: React.MouseEvent) => void;
    disabled?: boolean;
    description?: JSX.Element | string;
}

const WidgetHeaderButton: FunctionComponent<PropsWithChildren<Props>> = ({
    children,
    onClick,
    baseColor,
    disabled,
    description,
}: PropsWithChildren<Props>) => {
    const uuid = useUUID();

    const buttonElement = (
        <StyledButton
            onClick={onClick}
            style={baseColor ? { color: darker(baseColor) } : undefined}
            size="sm"
            variant={'outline-secondary'}
            disabled={disabled}
        >
            {children}
        </StyledButton>
    );

    if (description !== undefined) {
        return (
            <OverlayTrigger placement={'top'} overlay={<Tooltip id={`tooltip-${uuid}`}>{description}</Tooltip>}>
                {buttonElement}
            </OverlayTrigger>
        );
    } else {
        return buttonElement;
    }
};

export default WidgetHeaderButton;
