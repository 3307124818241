import React, { useContext } from 'react';
import styled from 'styled-components';
import { Button, Card, Carousel } from 'react-bootstrap';
import FilterContext from 'App/FilterContext';
import { BiReset } from 'react-icons/bi';
import FilterSettings from 'App/SettingsPanel/FilterSettings';
import InterestingnessSettings from 'App/SettingsPanel/InterestingnessSettings';

const ResetButton = styled(Button)`
    height: 40px;
    position: absolute;
    right: 35px;
    width: 40px;
    top: 0;

    svg {
        position: relative;
        top: -2px;
    }
`;

const ScrollContainer = styled.div`
    flex-grow: 1;

    display: block;
    position: relative;
    overflow-y: auto;

    box-shadow: 0 0 10px var(--dark);
    z-index: 1000;

    background: var(--light);
`;

const SettingsPanelContainer = styled.div`
    padding: 10px;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

const SettingsCard = styled(Card)`
    margin-bottom: 20px !important;
    border: none;
    background: none !important;

    &:last-child {
        margin-bottom: 0;
    }
`;

const SettingsCardHeader = styled(Card.Header)`
    background-color: transparent;
    display: flex;
    justify-content: center;
    padding: 5px 0;
`;

const TabContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
`;

const StyledCarousel = styled(Carousel)`
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    & > .carousel-control-prev {
        bottom: unset;
        height: 60px;
    }

    & > .carousel-control-next {
        bottom: unset;
        height: 60px;
    }

    & > .carousel-inner {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        & > .active {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }
    }
`;

const CarouselIcon = styled.div`
    color: #808080;
`;

interface Props {}

const SettingsPanel = (props: Props) => {
    const { clearAllFilters, filters } = useContext(FilterContext);

    return (
        <TabContainer id={'settings-panel'}>
            <StyledCarousel
                interval={null}
                fade={true}
                nextIcon={<CarouselIcon id={'settings-panel-next-icon'}> &#5125;</CarouselIcon>}
                prevIcon={<CarouselIcon> &#5130;</CarouselIcon>}
                indicators={false}
                defaultActiveIndex={0}
            >
                <Carousel.Item>
                    <ScrollContainer>
                        <SettingsPanelContainer className={'no-select'}>
                            <SettingsCard>
                                <SettingsCardHeader>
                                    <h4>Locate</h4>
                                    <ResetButton
                                        disabled={filters.length === 0}
                                        onClick={clearAllFilters}
                                        variant="light"
                                    >
                                        <BiReset />
                                    </ResetButton>
                                </SettingsCardHeader>
                                <FilterSettings />
                            </SettingsCard>
                        </SettingsPanelContainer>
                    </ScrollContainer>
                </Carousel.Item>
                <Carousel.Item>
                    <ScrollContainer>
                        <SettingsPanelContainer className={'no-select'}>
                            <SettingsCard>
                                <SettingsCardHeader>
                                    <h4>Interestingness</h4>
                                </SettingsCardHeader>
                                <InterestingnessSettings />
                            </SettingsCard>
                        </SettingsPanelContainer>
                    </ScrollContainer>
                </Carousel.Item>
            </StyledCarousel>
        </TabContainer>
    );
};

export default SettingsPanel;
