import React from 'react';
import useBoundingBox from 'tools/hooks/useBoundingBox';

interface Props extends React.SVGProps<SVGTextElement> {
    x: number;
    y: number;
    maxWidth?: number;
}

const LayerLabelText: React.FunctionComponent<Props> = ({
    maxWidth = Infinity,
    x,
    y,
    children,
    ...remainingTextProps
}: Props) => {
    const [ref, { width }] = useBoundingBox();

    // const renderCount = useRef(0);
    // console.log('Render count:', renderCount.current++);

    let scaleFactor = 1;
    if (width > maxWidth) {
        scaleFactor = maxWidth / width;
    }

    return (
        <svg>
            <g
                ref={ref}
                style={{
                    transform: `scale(${scaleFactor})`,
                    transformOrigin: `${x}px ${y}px`,
                }}
            >
                <text {...remainingTextProps} x={x} y={y}>
                    {children}
                </text>
            </g>
        </svg>
    );
};

export default LayerLabelText;
