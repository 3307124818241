import styled from 'styled-components';
import { Card } from 'react-bootstrap';
import { greyBaseColor } from 'styles/colors';

export const StyledCard = styled(Card)`
    flex-direction: row;
    border: none !important;
    background: none !important;
`;

export const RotatedText = styled.div`
    color: ${greyBaseColor};
    line-break: strict;
    padding: 10px 0 10px 5px;
    text-align: center;
    writing-mode: vertical-lr;
`;

export const CenteredCardBody = styled(Card.Body)`
    align-items: center;
    display: flex;
    padding-left: 0.85em;
`;
