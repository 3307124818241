import React from 'react';

interface Props {
    labelX?: string;
    labelY?: string;
    xMax: number;
    yMax: number;
    markIncompatibleScales: boolean;
}

const AxisLabels: React.FunctionComponent<Props> = ({ labelX, labelY, xMax, yMax, markIncompatibleScales }: Props) => {
    return (
        <>
            <text
                x={-2}
                y={2}
                style={{ dominantBaseline: 'text-before-edge', textAnchor: 'end' }}
                transform="rotate(-90)"
                fontSize={10}
            >
                {labelY}&nbsp;
                {markIncompatibleScales && <tspan style={{ fontWeight: 'bold' }}>(!)</tspan>}
            </text>
            <text
                x={xMax - 2}
                y={yMax - 4}
                style={{ dominantBaseline: 'text-after-edge', textAnchor: 'end' }}
                fontSize={10}
            >
                {labelX}
            </text>
        </>
    );
};

export default AxisLabels;
