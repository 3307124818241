import Symbols from 'icons/symbols';
import React from 'react';

interface Props extends Omit<React.SVGProps<SVGSVGElement>, 'className'> {
    x: number;
    y: number;
    size: number;
}

const ApplicabilityHint: React.FunctionComponent<Props> = ({ x, y, size }: Props) => {
    return (
        <svg x={x - size / 2} y={y - size * 1.5}>
            <g transform={`scale(${size / 16})`}>
                <Symbols.BouncingArrow />
            </g>
        </svg>
    );
};

export default ApplicabilityHint;
