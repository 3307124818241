import styled from 'styled-components';
import TinyBarChart from 'App/WidgetPanel/Widgets/ImageInputOutputWidget/TinyBarChart';

export const WrapperDiv = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -2px;
`;

export const SamplePairDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: start;
    border: 1px solid var(--gray-transparent);
    background: var(--light);
    margin: 5px;
    padding: 2px;
    font-size: 12px;
`;

export const SampleContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: start;
`;

export const StyledImg = styled.img`
    margin: 3px;
`;

export const StyledTinyBarChart = styled(TinyBarChart)`
    margin: 3px;
    border: 1px solid var(--gray);
`;
