import React, { useContext, useMemo } from 'react';
import dagre from 'dagre';
import { DagreLayerNode } from 'types/dagre-nodes/DagreLayerNode';
import ArchitectureGraphLayer from 'App/InspectionPanel/L3TreeComponent/ModelComponent/ArchitectureGraphLayer';
import FilterContext from 'App/FilterContext';
import { Structure } from 'types/nn-types/ModelGraph';
import HoverableLayerConnector from 'App/InspectionPanel/L3TreeComponent/ModelComponent/HoverableLayerConnector';
import { positionsToPathString } from 'tools/helpers';
import ModelContext from 'App/ModelContext';

interface Props {
    dagreArchitectureGraph: dagre.graphlib.Graph<DagreLayerNode>;
}

const ArchitectureGraphComponent: React.FunctionComponent<Props> = (props: Props) => {
    const { model } = useContext(ModelContext);
    const g = props.dagreArchitectureGraph;
    const { getStructuresMatchedByFilters } = useContext(FilterContext);

    const modelStructures = useMemo(() => getStructuresMatchedByFilters(model), [getStructuresMatchedByFilters, model]);

    const nodeElements = g.nodes().map((n, idx) => {
        const node = g.node(n);

        return (
            <ArchitectureGraphLayer
                key={idx}
                modelGraphNode={node.modelGraphNode}
                x={node.x}
                y={node.y}
                width={node.width}
                height={node.height}
            />
        );
    });

    const linkElements = g.edges().map((e, idx) => {
        let filteredStructure: Structure | undefined;
        const edge = g.edge(e).points;

        if (modelStructures.length > 0) {
            // This approach assumes that a connection between two layers is only ever associated with a single structure.
            filteredStructure = modelStructures.find((modelStructure) => {
                const paths = model.graph.getPathsBelongingTo(modelStructure);

                return paths.some((path) => path.some((link) => link.source === e.v && link.target === e.w));
            });
        }

        return (
            <HoverableLayerConnector
                key={`${e.v}_${e.w}`}
                d={positionsToPathString(edge)}
                filteredStructure={filteredStructure}
            />
        );
    });

    return (
        <g>
            {linkElements}
            {nodeElements}
        </g>
    );
};

export default ArchitectureGraphComponent;
