import { LevelOfAbstraction } from 'types/inspection-types/LevelOfAbstraction';
import React from 'react';
import { Entity } from 'types/inspection-types/Entity';

interface IInspectionPanelContext {
    lofa: LevelOfAbstraction;
    hierarchyPathEntityIDs: string[];
    getCurrentlySelectedEntityID: () => string | null; // Acts as a stack, containing the entity IDs that have to be descended through
    ascendLofa: (levels?: number) => void;
    descendLofa: (focusedEntityId: string) => void;
    focus: (entity: Entity) => void;
}

const defaultContext: IInspectionPanelContext = {
    lofa: LevelOfAbstraction.MULTI_MODEL,
    hierarchyPathEntityIDs: [],
    getCurrentlySelectedEntityID: () => {
        // Initial value. Replaced by context provider.
        return '';
    },
    ascendLofa: () => {
        // Initial value. Replaced by context provider.
    },
    descendLofa: () => {
        // Initial value. Replaced by context provider.
    },
    focus: () => {
        // Initial value. Replaced by context provider.
    },
};

const InspectionPanelContext = React.createContext(defaultContext);

export default InspectionPanelContext;
