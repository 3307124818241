import React from 'react';
import * as uuid from 'uuid';
import { rgb } from 'd3-color';
import { interpolateRdBu } from 'd3-scale-chromatic';
import { scaleLinear } from '@visx/scale';

export const useSVGFilter = (filter: JSX.Element): [string, JSX.Element] => {
    const filterIdRef = React.useRef<string>(uuid.v4());

    const filterElement = <filter id={filterIdRef.current}>{filter}</filter>;

    return [filterIdRef.current, filterElement];
};

const interestingnessColorScale = (t: number) => {
    const scale = scaleLinear<number>().domain([0, 1]).range([0.95, 0.05]);
    return interpolateRdBu(scale(t) ?? 0);
};

export const useInterestingnessSVGFilter = (normalizedInterestingness: number): [string, JSX.Element] => {
    const filterIdRef = React.useRef<string>(uuid.v4());

    const iCleaned =
        normalizedInterestingness >= 0 && normalizedInterestingness < Infinity ? normalizedInterestingness : 0;

    const rgbColor = rgb(interestingnessColorScale(iCleaned) ?? '#ffffff');

    const filterDef = (
        <filter id={filterIdRef.current}>
            <>
                {/* De-colorize models. */}
                <feColorMatrix
                    type="matrix"
                    values=" 0.000  0.000  0.000  1.000  0.000
                             0.000  0.000  0.000  1.000  0.000
                             0.000  0.000  0.000  1.000  0.000
                             0.000  0.000  0.000  1.000  0.000"
                ></feColorMatrix>
                {/* Colorize according to color scale. */}
                <feColorMatrix
                    values={`${rgbColor.r / 255} 0 0 0 0 0 ${rgbColor.g / 255} 0 0 0 0 0 ${
                        rgbColor.b / 255
                    } 0 0 0 0 0 1.0 0`}
                />
            </>
        </filter>
    );

    return [filterIdRef.current, filterDef];
};
