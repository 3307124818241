import React, { Dispatch, ReactNode, SetStateAction, useEffect } from 'react';
import {
    LayerInnardComponentProps,
    LayoutProps,
} from 'App/InspectionPanel/L2ArchitectureComponent/LayerComponent/LayerInnardComponent/LayerInnardComponentProps';
import LabeledIcon from 'App/InspectionPanel/L2ArchitectureComponent/LayerComponent/LayerInnardComponent/InnardIcon/LabeledIcon';

type Size = { height: number; width: number };

const BoxedIcon = ({
    contentSize,
    onSizeChange,
    ...props
}: LayerInnardComponentProps &
    LayoutProps & { children: ReactNode; contentSize: Size; setContentSize: Dispatch<SetStateAction<Size>> }) => {
    const padding = props.padding !== undefined ? props.padding : 10;

    useEffect(() => {
        onSizeChange(contentSize.width + 2 * padding, contentSize.height + 2 * padding);
    }, [contentSize, padding, onSizeChange]);

    return (
        <g transform={`translate(${props.x - contentSize.width / 2} ${props.y - contentSize.height / 2})`}>
            {props.children}
            <LabeledIcon
                layerGraphNode={props.layerGraphNode}
                iconWidth={props.iconWidth}
                iconHeight={props.iconHeight}
                onLayoutFinished={(size: Size) => props.setContentSize(size)}
            />
        </g>
    );
};

export default BoxedIcon;
