import { ModelInfo } from 'types/nn-types/ModelInfo';
import { ModelGraph } from 'types/nn-types/ModelGraph';
import { CheckpointCatalog } from 'types/nn-types/CheckpointInfo';
import { ModelStats } from 'types/nn-types/ModelStats';
import { ModelPreferences } from 'types/nn-types/ModelPreferences';
import ModelSourceCode from 'types/nn-types/ModelSourceCode';
import { Entity } from 'types/inspection-types/Entity';
import { InterestingnessDescriptor } from 'types/inspection-types/InterestingnessDescriptor';
import { EntityType } from 'types/inspection-types/EntityType';

export interface Model extends Entity {
    info: ModelInfo;
    graph: ModelGraph;
    checkpointCatalog: CheckpointCatalog;
    stats: ModelStats;
    sourceCode: ModelSourceCode;
    preferences: ModelPreferences;
    index: number;
}

// This is needed for runtime typechecks to enable iterating over interface keys (for type-guard)
class ModelImplementation implements Model {
    id = '';
    name = '';
    type: EntityType = EntityType.MODEL_MISC;
    info: ModelInfo = {
        name: '',
        label: '',
        children: [],
        timestamp: new Date(),
        parents: [],
        sourceFile: '',
        classes: [],
    };
    graph: ModelGraph = new ModelGraph();
    checkpointCatalog: CheckpointCatalog = {
        layersInCheckpoints: [],
        checkpoints: [],
    };
    stats: ModelStats = {
        numTrainableParameters: 0,
        numLayers: 0,
        executionTimeTestset: {
            value: 0,
            unit: '',
        },
        memoryUsage: {
            value: 0,
            unit: '',
        },
        modelSaveSize: {
            value: 0,
            unit: '',
        },
    };
    sourceCode: ModelSourceCode = {
        modelFunction: '',
        metadataFunction: '',
        fullSource: '',
    };
    preferences: ModelPreferences = {
        baseColor: '',
    };
    interestingness: InterestingnessDescriptor[] = [];
    index = 0;
}

export const MODEL_INSTANCE = new ModelImplementation();

// Type-guard for the model type
export function isModel(entity: unknown): entity is Model {
    let result = true;

    Object.keys(MODEL_INSTANCE).forEach((key: string) => {
        result = result && (entity as any)[key] !== undefined; // eslint-disable-line @typescript-eslint/no-explicit-any
    });

    return result;
}

// Type-guard for an array of models
export function isModelTree(entity: unknown): entity is Model[] {
    if (!Array.isArray(entity)) {
        return false;
    }

    entity.forEach((e) => {
        if (!isModel(e)) {
            return false;
        }
    });

    return true;
}
