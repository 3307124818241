import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Portal, Tooltip } from '@visx/tooltip';
import ModelContext from 'App/ModelContext';
import ZoomStateContext from 'App/InspectionPanel/ZoomStateContext';
import { useUpdate } from 'react-use';
import { svgToScreenPos } from 'tools/helpers';

const BackgroundCircle = styled.circle<{ $strokeWidth: number; $toggled: boolean }>`
    fill: ${({ $toggled }) => ($toggled ? 'var(--dark)' : 'var(--light)')};
    stroke: var(--gray);
    stroke-width: ${({ $strokeWidth }) => $strokeWidth + 'px'};
`;

const InfoText = styled.text<{ $fontSize: number }>`
    fill: var(--gray);
    dominant-baseline: central;
    text-anchor: middle;
    font-size: ${({ $fontSize }) => $fontSize + 'px'};
`;

interface Props {
    x: number;
    y: number;
    iconSize?: number;
}

const MetadataInfoButton: React.FunctionComponent<Props> = ({ x, y, iconSize = 12 }: Props) => {
    const svgElementRef: React.RefObject<SVGSVGElement> = React.useRef<SVGSVGElement>(null);
    const [showTooltip, setShowTooltip] = React.useState<boolean>(false);

    const onClickHandler = (e: React.MouseEvent) => {
        setShowTooltip(!showTooltip);
        e.stopPropagation();
    };

    const onDoubleClickCatcher = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    const strokeWidth = iconSize / 15;
    const fontSize = iconSize / 1.4;

    return (
        <g onClick={onClickHandler} onDoubleClick={onDoubleClickCatcher} transform={`translate(${x} ${y})`}>
            <svg className={'no-select'} ref={svgElementRef}>
                <BackgroundCircle
                    $toggled={showTooltip}
                    $strokeWidth={strokeWidth}
                    cx={iconSize / 2}
                    cy={iconSize / 2}
                    r={iconSize / 2 - strokeWidth}
                />
                <InfoText $fontSize={fontSize} x={iconSize / 2} y={iconSize / 2}>
                    i
                </InfoText>
                {showTooltip && svgElementRef.current && (
                    <MIBTooltip svgElement={svgElementRef.current} x={iconSize / 2} y={iconSize / 2} />
                )}
            </svg>
        </g>
    );
};

interface TooltipProps {
    svgElement: SVGSVGElement;
    x: number;
    y: number;
}

const MIBTooltip: React.FunctionComponent<TooltipProps> = ({ svgElement, x, y }: TooltipProps) => {
    const { model } = useContext(ModelContext);
    const { zoom } = useContext(ZoomStateContext);

    const update = useUpdate();
    useEffect(update, [update, zoom]);

    const pos = svgToScreenPos(svgElement, x, y);

    return (
        <Portal>
            <Tooltip left={pos.x} top={pos.y}>
                <table>
                    <tbody>
                        <tr>
                            <td>Name:</td>
                            <td>{model.name}</td>
                        </tr>
                        <tr>
                            <td>Creation date:</td>
                            <td>{model.info.timestamp.toUTCString()}</td>
                        </tr>
                        <tr>
                            <td>Nr. of Checkpoints:</td>
                            <td>{model.checkpointCatalog.checkpoints.length}</td>
                        </tr>
                    </tbody>
                </table>
            </Tooltip>
        </Portal>
    );
};

export default MetadataInfoButton;
