import React from 'react';
import { Model, MODEL_INSTANCE } from 'types/nn-types/Model';

interface IModelContext {
    model: Model;
}

const defaultContext: IModelContext = {
    model: MODEL_INSTANCE,
};

const ModelContext = React.createContext(defaultContext);

export default ModelContext;
