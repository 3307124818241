import React from 'react';
import { InterestingnessType } from 'types/inspection-types/InterestingnessType';
import { Entity } from 'types/inspection-types/Entity';

interface IInterestingnessContext {
    activeInterestingnesses: InterestingnessType[];
    toggleInterestingness: (interestingness: InterestingnessType) => void;
    isInterestingnessActive: (interestingness: InterestingnessType) => boolean;
    getInterestingness: (entity: Entity) => number | undefined;
    getMaxInnerInterestingness: (entity: Entity) => number | undefined;
}

const defaultContext: IInterestingnessContext = {
    activeInterestingnesses: [],
    toggleInterestingness: () => {
        // Initial value. Replaced by context provider.
    },
    isInterestingnessActive: () => {
        // Initial value. Replaced by context provider.
        return false;
    },
    getInterestingness: () => {
        // Initial value. Replaced by context provider.
        return undefined;
    },
    getMaxInnerInterestingness: () => {
        // Initial value. Replaced by context provider.
        return undefined;
    },
};

const InterestingnessContext = React.createContext(defaultContext);

export default InterestingnessContext;
