import React from 'react';
import GroupsContext from 'App/GroupsContext';
import { Group } from 'types/nn-types/Group';

const GroupsContextProvider = ({ children, groups }: { children: React.ReactNode; groups: Group[] }) => {
    return (
        <GroupsContext.Provider
            value={{
                groups,
            }}
        >
            {children}
        </GroupsContext.Provider>
    );
};

export default GroupsContextProvider;
