import React from 'react';
import { EntityType, getEntityTypeColor } from 'types/inspection-types/EntityType';
import { Structure } from 'types/nn-types/ModelGraph';
import { useHover } from 'react-use';
import { useBrush, useLink } from 'tools/hooks/useLinkAndBrush';

interface Props {
    d: string;
    filteredStructure: Structure | undefined;
}

const HoverableLayerConnector: React.FunctionComponent<Props> = ({ d, filteredStructure }: Props) => {
    const [isLinked] = useLink<EntityType | undefined>('filter-hovered', filteredStructure);

    const layerConnector = (hovered: boolean) => (
        <path
            d={d}
            style={{
                fill: 'none',
                stroke: filteredStructure ? getEntityTypeColor(filteredStructure) : '#a7a7a7',
                strokeWidth: `${hovered || isLinked ? 3 : 2}px`,
            }}
        />
    );

    const [hoverableLayerConnector, hovered] = useHover(layerConnector);
    useBrush<EntityType | undefined>('filter-badge-hovered', hovered ? filteredStructure : undefined);

    return <>{hoverableLayerConnector}</>;
};

export default HoverableLayerConnector;
