import React from 'react';
import { PromisedWidgetDefinition, WidgetDefinitionWithId } from 'types/inspection-types/WidgetDefinition';
import { LevelOfAbstraction } from 'types/inspection-types/LevelOfAbstraction';
import { WidgetTable } from 'types/inspection-types/WidgetTable';
import { WidgetIdentifier } from 'App/WidgetContextProvider';

export interface IWidgetContext {
    widgetTable: WidgetTable;
    addWidget: (wd: PromisedWidgetDefinition, lofa: LevelOfAbstraction) => string;
    removeWidget: (widgetId: string) => void;
    moveWidget: (widgetId: string, targetGroupId?: string) => void;
    getAssociatedWidgets: (entityId: string) => WidgetDefinitionWithId[];
    useOnWidgetAdded: (cb: (widgetIdentifier: WidgetIdentifier) => void) => void;
}

const defaultContext: IWidgetContext = {
    widgetTable: {} as WidgetTable,
    addWidget: () => {
        // Initial value. Replaced by context provider.
        return '';
    },
    removeWidget: () => {
        // Initial value. Replaced by context provider.
    },
    moveWidget: () => {
        // Initial value. Replaced by context provider.
    },
    getAssociatedWidgets: () => {
        // Initial value. Replaced by context provider.
        return [];
    },
    useOnWidgetAdded: () => {
        // Initial value. Replaced by context provider.
        return [];
    },
};

const WidgetContext = React.createContext(defaultContext);

export default WidgetContext;
