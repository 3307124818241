import * as mathjs from 'mathjs';

export type DataValue = number | number[] | string | mathjs.Matrix | Date;
export type DataRow = Record<string, DataValue>;
export type DataArray = Array<DataRow>;

export const isMatrix = (v: DataValue): v is mathjs.Matrix => {
    return (
        v !== undefined && !Array.isArray(v) && typeof v !== 'number' && typeof v !== 'string' && !(v instanceof Date)
    );
};

export const isNumberArray = (v: DataValue): v is number[] => {
    return Array.isArray(v);
};

export const isNumber = (v: DataValue): v is number => {
    return typeof v === 'number';
};

export const isString = (v: DataValue): v is number => {
    return typeof v === 'string';
};

export const isDate = (v: DataValue): v is Date => {
    return typeof v === 'object' && v instanceof Date;
};
