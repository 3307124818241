import * as React from 'react';
import styled from 'styled-components';
import InspectionSVG from 'App/InspectionPanel/InspectionSVG';
import ClassSelectorPanel from 'App/InspectionPanel/ClassSelectorPanel';
import Navigation from 'App/InspectionPanel/Navigation';
import WidgetPanel from 'App/WidgetPanel/WidgetPanel';

const ScrollContainer = styled.div`
    flex-grow: 1;

    display: block;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;

    background: #e5e7ea;
`;

const InspectionPanelContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
`;

interface Props {}

const InspectionPanel = (props: Props) => {
    return (
        <ScrollContainer>
            <InspectionPanelContainer id={'inspection-panel-container'}>
                <Navigation />
                <ClassSelectorPanel marginTop={60} />
                <WidgetPanel marginRight={40} marginBottom={40} />
                <InspectionSVG />
            </InspectionPanelContainer>
        </ScrollContainer>
    );
};

export default InspectionPanel;
