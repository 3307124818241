import React from 'react';
import { Entity } from 'types/inspection-types/Entity';

interface ISelectionContext {
    selectedEntities: string[];
    select: (entityOrEntityId: Entity | string) => void;
    unselect: (entityOrEntityId: Entity | string) => void;
    toggleSelection: (entityOrEntityId: Entity | string) => void;
    isSelected: (entityOrEntityId: Entity | string) => boolean;
}

const defaultContext: ISelectionContext = {
    selectedEntities: [],
    select: () => {
        // Initial value. Replaced by context provider.
    },
    unselect: () => {
        // Initial value. Replaced by context provider.
    },
    toggleSelection: () => {
        // Initial value. Replaced by context provider.
    },
    isSelected: () => {
        // Initial value. Replaced by context provider.
        return false;
    },
};

const SelectionContext = React.createContext(defaultContext);

export default SelectionContext;
